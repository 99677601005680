import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import FloatingText from "../components/floating-text"
import FullWidthCTA from "../components/full-width-cta"

const Index = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout
      location={location}
      title={siteTitle}
      heroTitle="The Social Layer of the Metaverse"
      heroSubtitle="Creating connections through proof of community"
    >
      <SEO title="The Social Layer of the Metaverse" />
      <FloatingText
        textOne="Virtual worlds are blossoming. In person relationships are losing their richness."
        textTwo="Now is the time to redefine how people interact with eachother, outside of their homes."
        textThree="Let’s rebuild the public square, together."
      />
      <FullWidthCTA
        header="Announcing Clink"
        body="A new way to connect in the real world, with novel incentive mechanisms and a proof-of-community protocol."
        falloff="Read the blog post"
      />
    </Layout>
  )
}

export default Index

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
