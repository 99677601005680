import React, { Component } from "react"
import styled from "styled-components"

const Container = styled.div`
  background-color: black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 110vh;
`

const TextContainer = styled.div`
  margin: auto;
  width: 80%;

  h2 {
    color: white;
    font-family: "Raleway", sans-serif;
    font-weight: 300;    
    font-size: 42px;
    width: 900px;

    :nth-child(1) { 
      margin: 0 auto 22vh 0;
    }

    :nth-child(2) {
      margin: 22vh 0 0 auto;
      text-align: right;
    }

    :nth-child(3) {
      text-align: center;
      margin: 22vh auto 0 auto;
    }
  }
  

  @media screen and (max-width: 900px) { 
    margin: 12% auto 12% auto;

    h2 { 
      font-size: 22px;
      font-weight: bold;
      width: 86%;
    }
  }
`

class FloatingText extends Component {
  render() {
    return (
      <Container>
        <TextContainer>
          <h2>{this.props.textOne}</h2>
          <h2>{this.props.textTwo}</h2>
          <h2>{this.props.textThree}</h2>
        </TextContainer>
      </Container>
    )
  }
}

export default FloatingText
