import React, { Component } from "react"
import styled from "styled-components"
import { Link } from "gatsby"

const Container = styled.div`
  background-color: black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 60vh;
`

const TextContainer = styled(Link)`
  background-color: white;
  border-radius: 12px;
  text-decoration: none;
  box-shadow: none;
  color: black;
  margin: 10vh auto 10vh auto;
  width: 80%;

  h2 {
    color: black;
    font-family: "Raleway", sans-serif;
    font-weight: bold;
    font-size: 42px;
    padding: 24px 36px;
  }

  p {
    font-family: "Roboto", sans-serif;

    :nth-child(2) {
      padding: 0 36px;
      font-size: 36px;
      font-weight: 300;
      width: 50%;
    }

    :nth-child(3) {
      text-align: right;
      font-size: 28px;
      font-weight: 300;
      padding: 24px 36px 24px 0;
      margin: 0;
    }
  }

  @media screen and (max-width: 900px) {
    h2 {
      font-size: 24px;
    }

    p {
      font-size: 16px;
      :nth-child(2) {
        font-size: 18px;
        width: 80%;
      }
      :nth-child(3) {
        font-size: 18px;
      }
    }
  }
`

class FullWidthCTA extends Component {
  render() {
    return (
      <Container>
        <TextContainer to="/introducing-clink">
          <h2>{this.props.header}</h2>
          <p>{this.props.body}</p>
          <p>
            {this.props.falloff}{" "}
            <span role="img" aria-label="point right emoji">
              👉
            </span>
          </p>
        </TextContainer>
      </Container>
    )
  }
}

export default FullWidthCTA
